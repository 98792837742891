import React, { useContext, useState } from 'react'
import { ProjectContext } from '../ProjectContext';
import { useNavigate } from 'react-router-dom';

export default function OverView() {
  const {
    toastError,
    setaccount, setisOverview
  } = useContext(ProjectContext)
  const [oAddr, setoAddr] = useState(null);
  var navigate = useNavigate();

  const getDta = async () => {
    if (!oAddr) {
      toastError("Enter address")
      return
    }
    setaccount(oAddr)
    setisOverview(true)
    navigate('/dashboard')
  };
  return (
    <div>
      <section className="dashboard-section body-collapse pay step deposit-money withdraw-money" style={{ background: "#F7F9FD" }}>
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="row pb-120  justify-content-center">
                <div className="col-12">
                  <div className="table-area">
                    <div>
                      <form action="#" >
                        <div className="row justify-content-center mt-5">
                          <div className="col-xl-7 p-4">
                            <div className="exchange-content">
                              <div className="send-banance">
                                <span className="mdr">Address</span>
                                <div className="input-area">
                                  <input
                                    className="xxlr"
                                    placeholder={"Enter address"}
                                    type="text"
                                    onChange={(e) => setoAddr(e.target.value)}
                                  />

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="footer-area mt-40">
                          <a type='button' className="active" onClick={() => getDta()}>
                            Go🚀
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
