import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Coman/Sidebar";
import { ProjectContext } from "../ProjectContext";
import axios from 'axios'

export default function Account() {
  const {
    account,
    dbuser,
    connectMetamask, getuserDB, toastSuccess, toastError
  } = useContext(ProjectContext)


  const [email, setemail] = useState('');
  const [uname, setuname] = useState('');
  const [mobilenumber, setmobilenumber] = useState('');
  const upprofile = async () => {
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "update",
        key: process.env.REACT_APP_KEY,
        address: account,
        uname: uname,
        email: email,
        mobilenumber: mobilenumber,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
        getuserDB()
      });
  }

  useEffect(() => {
    if (dbuser !== null) {
      setemail(dbuser.email)
      setuname(dbuser.uname)
      setmobilenumber(dbuser.mobile_number)
    }
  }, [dbuser])

  return (
    <>
      <Sidebar />
      <section className="dashboard-section body-collapse account">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row justify-content-center">
                <div className="col-xxl-11 ">
                  <div className="tab-main">
                    <div className="tab-content mt-40">
                      <div
                        className="tab-pane fade show active"
                        id="account"
                        role="tabpanel"
                        aria-labelledby="account-tab"
                      >
                        <div className="upload-avatar">
                          <div className="avatar-left d-flex align-items-center">
                            <div className="instraction">
                              <div className="owner-info">
                                <ul>
                                  <li className="d-flex gap-2">
                                    <p>Address:</p>
                                    <span className="mdr">{account}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                          <div className="row justify-content-center">
                            <div className="col-md-4">
                              <div className="single-input">
                                <label htmlFor="fName">Name</label>
                                <input
                                  type="text"
                                  id="fName"
                                  placeholder="Name"
                                  value={uname}
                                  onChange={(e) =>
                                    setuname(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="single-input">
                                <label htmlFor="Email">Email</label>
                                <input
                                  type="email"
                                  id="Email"
                                  placeholder="Email@mail.com"
                                  value={email}
                                  onChange={(e) =>
                                    setemail(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="single-input">
                                <label htmlFor="phone">Phone</label>
                                <input
                                  type="number"
                                  id="phone"
                                  placeholder="9876543210"
                                  value={mobilenumber}
                                  onChange={(e) =>
                                    setmobilenumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-12 pt-3">
                              <div className="btn-border">
                                {dbuser ?dbuser.uname!==null ?'':
                                <button className="cmn-btn" onClick={() => upprofile()}>Update</button>:''}
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
