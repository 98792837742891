import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../Components/Coman/Sidebar'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios'
import { Line } from "rc-progress";

export default function Withdrawal() {
    const {
        account,
        balance,
        toastSuccess,
        toastError,
        getudata,
        tokenrate,
        dbuser, dbbalance,
        ethers, isOverview,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)

    const [amount, setamount] = useState(0);
    const [rcv_amount, setrcv_amount] = useState(0);
    const [fees, setfees] = useState(0);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);

    const withdraw = async () => {
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!amount || amount < 0) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (dbuser.restake) {
            toastError("Restake account");
            settrdone(false);
            return;
        }
        if (amount < 10) {
            toastError("Min $10 required");
            settrdone(false);
            return;
        }
        if (Number(amount) > dbbalance) {
            toastError("Insufficient Balance");
            settrdone(false);
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                address: account,
                amount: amount,
                token: total_tokens,
                fee: fees,//admin
                t_rcv: rcv_amount,
                rate: tokenrate,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data);
            });
        getuserDB();
        // getpData()
        // getData()
        settrdone(false);
    }
    useEffect(() => {
        var tmt = amount / tokenrate;
        var tmtfee = (tmt * 20) / 100;
        settotal_tokens(tmt);
        setfees(tmtfee);
        setrcv_amount(tmt - tmtfee);
    }, [amount, tokenrate]);


    return (
        <>
            <Sidebar />
            <section className="dashboard-section body-collapse pay step deposit-money withdraw-money">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="main-content">
                            <div className="head-area d-flex align-items-center justify-content-between">
                                <h4>Withdraw Funds</h4>
                                <div className="icon-area">
                                    <img src="assets-new/images/icon/support-icon.png" alt="icon" />
                                </div>
                            </div>
                            {/* <div className="choose-recipient">
                                <div className="step-area">
                                    <span className="mdr">Step 2 of 3</span>
                                    <h5>Set Amount of transfer</h5>
                                </div>
                            </div> */}
                            {/* <div className="col-12">
                                <div className="transactions-area mt-40">
                                    <div className="section-text mb-0">
                                        <div className="d-flex justify-content-between mb-4">
                                            <h5>Withdrawal Capability </h5>
                                            <h5>3X</h5>
                                        </div>
                                        <Line
                                            percent={dbuser ? dbuser.x_cper : 0}
                                            strokeColor="#9f24e7"
                                            trailColor="#02104d"
                                        />
                                        <div className="d-flex justify-content-between mt-3">
                                            <h6>${dbuser ? dbuser.t_staking : 0}</h6>
                                            <h6>${dbuser ? dbuser.xIncome : 0}</h6>
                                            <p>${dbuser ? dbuser.x4_income : 0}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row pt-120 pb-120 justify-content-center">
                                <div className="col-md-7">
                                    <div className="table-area" style={{ boxShadow: " 3px 3px 20px 0px #b0aeae" }}>
                                        <form action="#">
                                            <div className="send-banance">
                                                <div className='d-flex justify-content-between'>
                                                    <span className="mdr">How much you want to Withdraw?</span>
                                                    <span className="mdr">${dbbalance}</span>
                                                </div>
                                                <input className="xxlr my-3" placeholder={0.00} type="number" value={amount}
                                                    onChange={(e) =>
                                                        setamount(e.target.value)
                                                    } />
                                                <div className="d-flex justify-content-between">
                                                    <p>Fees</p>
                                                    <p>$ {fees} (20%)</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Total Recieve</p>
                                                    <p>$ {rcv_amount}</p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {!isOverview ?
                                        <div className="footer-area mt-40">
                                            <a href="#" className="active" onClick={() => withdraw()}>Withdraw Now</a>
                                        </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
