import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../Components/Coman/Sidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function Bonanza() {
    const { account, dbuser } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "bonanza",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
            });
    };
    useEffect(() => {
        getData();
    }, [account]);



    return (
        <>
            <Sidebar />
            <section className="dashboard-section body-collapse transactions">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="head-area">
                            <div className="row">
                                <div className="col-lg-5 col-md-4">
                                    <h4>Bonanza</h4>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="transactions-main">
                                    {/* <div className="top-items">
                                        <h6>Direct Referral</h6>
                                    </div> */}
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Reward</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td className="text-center" colSpan={10}>
                                                        {isLoading ? "Data is loading" : ""}
                                                    </td>
                                                </tr>
                                                {!isLoading ? (
                                                    !data.length ? (
                                                        <tr className="text-center">
                                                            <td className="text-center" colSpan={10}>
                                                                <span className="w-100">No data found</span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                {data.map((ele, i) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {i+1}
                                                            </td>
                                                            <td>
                                                                $ {ele.amount}
                                                            </td>
                                                            <td>
                                                                {ele.reward}
                                                            </td>
                                                            <td>
                                                                {dbuser ? dbuser.slots.includes(ele.slot)?"Achieved":"Not Achieved":"-"}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
