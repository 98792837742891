import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const { disconnectNow } = useContext(ProjectContext);
  var navigate = useNavigate();

  const [sidebar, setSidebar] = useState(false);
  const [subMenup, setsubMenupp] = useState(false);
  const currentPath = window.location.pathname;
  const [subopnep, setSubopenp] = useState(true);
  const [subopne2p, setSubopen2p] = useState(true);
  const [subopne3p, setSubopen3p] = useState(true);
  const [subopne4p, setSubopen4p] = useState(true);
  const [subopne5p, setSubopen5p] = useState(true);
  const [subopne6p, setSubopen6p] = useState(true);
  const [subopne7p, setSubopen7p] = useState(true);

  const logout = () => {
    disconnectNow();
    navigate("/");
  };

  return (
    <>
      <header className="header-section body-collapse header-section-1 animated fadeInDown header-fixed">
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center  justify-content-lg-end justify-content-between nav-ari">
                <div
                  className="sidebar-icon d-lg-none d-block"
                  onClick={() => setSidebar(true)}
                >                  <i class="fa fa-bars" aria-hidden="true"></i>
                </div>
                <div className="d-flex ">
                  <button
                    type="button"
                    className="cmn-btn "
                    style={{
                      boxShadow: "black 4px 7px 12px",
                      width: "140px",
                    }}
                    onClick={() => logout()}
                  >
                    Logout <i class="fa-solid fa-arrow-right-from-bracket"></i>
                  </button>
                </div>
              </div>
              <div className={sidebar ? "sidebar-wrapper" : "sidebar-wrapper d-lg-block d-none"}>
                <div className="close-btn" onClick={() => setSidebar(false)}>
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="#">
                    <img
                      src="/assets/img/new-img/logo.svg"
                      className="logo"
                      alt="logo"
                      style={{ width: "80%" }}
                    />
                  </Link>
                </div>
                <ul>
                  <li
                    className={`${currentPath === "/dashboard" ||
                      currentPath === "/dashboard"
                      ? " active"
                      : " "
                      }`}
                  >
                    <Link
                      to="/dashboard"
                      onClick={() => setsubMenupp(!subMenup)}
                    >
                      <img src="/assets/img/new-img/new-icon/dashboard.svg" />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li
                    className={`${currentPath === "/slots-purchase" ||
                      currentPath === "/slots-history"
                      ? " active"
                      : " "
                      }`}
                    onClick={() => setSubopenp(!subopnep)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a>
                        <img src="/assets/img/new-img/new-icon/slots.svg" />{" "}
                        <span>Membership </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopnep ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        style={{ border: "3px solid white" }}
                        className={`${currentPath === "/slots-purchase" ? " active" : " "
                          }`}
                      >
                        <Link to="/slots-purchase">
                          <img src="/assets/img/new-img/new-icon/purchase.svg" />{" "}
                          <span> Purchase</span>
                        </Link>
                      </li>
                      <li
                        style={{ border: "3px solid white" }}
                        className={`${currentPath === "/slots-history" ? " active" : " "
                          }`}
                      >
                        <Link to="/slots-history">
                          <img src="/assets/img/new-img/new-icon/history.svg" />{" "}
                          <span> History</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/my-team">
                      <img src="/assets/img/new-img/new-icon/my_all_team.svg" />{" "}
                      <span>My All Team</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pool-entry">
                      <img src="/assets/img/new-img/new-icon/pool_entry.svg" />{" "}
                      <span>Pool Entry</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/bonanza">
                      <img src="/assets/img/new-img/new-icon/auto_pool.svg" />{" "}
                      <span>Bonanza</span>
                    </Link>
                  </li>
                  <li
                    className={`${currentPath === "/self-cashback" ||
                      currentPath === "/self-cashback"
                      ? "d-block active"
                      : "d-block"
                      }`}
                    onClick={() => setSubopen2p(!subopne2p)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a className="d-flex">
                        <img src="/assets/img/new-img/new-icon/pool_reward.svg" />{" "}
                        <span>Pool Reward </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopne2p ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        className={`${currentPath === "/magic-pool" ? "active" : " "
                          }`}
                      >
                        <Link to="/magic-pool">
                          <img src="/assets/img/new-img/new-icon/pool_reward.svg" />{" "}
                          <span> Magic Pool</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/earth-pool">
                          <img src="/assets/img/new-img/new-icon/pool_reward.svg" />{" "}
                          <span> Earth Pool</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/global-pool">
                          <img src="/assets/img/new-img/new-icon/pool_reward.svg" />{" "}
                          <span> Global Pool</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${currentPath === "/1x-reward" ||
                      currentPath === "/1x-reward"
                      ? "d-block active"
                      : "d-block"
                      }`}
                    onClick={() => setSubopen4p(!subopne4p)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a className="d-flex">
                        <img src="/assets/img/new-img/new-icon/backup_reward.svg" />{" "}
                        <span>Backup Reward </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopne4p ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        className={`${currentPath === "/1x-reward" ? "active" : " "
                          }`}
                      >
                        <Link to="/1x-reward">
                          <img src="/assets/img/new-img/new-icon/1x_reward.svg" />{" "}
                          <span> 1x Reward</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/mining-reward">
                          <img src="/assets/img/new-img/new-icon/mining_rewards.svg" />{" "}
                          <span> Mining Reward</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${currentPath === "/cash-back" ||
                      currentPath === "/cash-back"
                      ? "d-block active"
                      : "d-block"
                      }`}
                    onClick={() => setSubopen5p(!subopne5p)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a className="d-flex">
                        <img src="/assets/img/new-img/new-icon/affiliate_reward.svg" />{" "}
                        <span>Affiliate Reward </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopne5p ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        className={`${currentPath === "/cash-back" ? "active" : " "
                          }`}
                      >
                        <Link to="/cash-back">
                          <img src="/assets/img/new-img/new-icon/cash_back.svg" />{" "}
                          <span> Cash Back </span>
                        </Link>
                      </li>

                      <li>
                        <Link to="/direct-referral">
                          <img src="/assets/img/new-img/new-icon/direct_referral.svg" />{" "}
                          <span> Direct Referral </span>
                        </Link>
                      </li>

                      <li>
                        <Link to="/community-building">
                          <img src="/assets/img/new-img/new-icon/affiliate_reward.svg" />{" "}
                          <span> Community Building</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${currentPath === "/working-royalty" ||
                      currentPath === "/working-royalty"
                      ? "d-block active"
                      : "d-block"
                      }`}
                    onClick={() => setSubopen6p(!subopne6p)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a className="d-flex">
                        <img src="/assets/img/new-img/new-icon/royalty_reward.svg" />{" "}
                        <span>Royalty Reward </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopne6p ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        className={`${currentPath === "/leadership-reward" ? "active" : " "
                          }`}
                      >
                        <Link to="/leadership-reward">
                          <img src="/assets/img/new-img/new-icon/leadership_reward.svg" />{" "}
                          <span> Leadership Reward </span>
                        </Link>
                      </li>

                      <li>
                        <Link to="/reprformance-reward">
                          <img src="/assets/img/new-img/new-icon/performance_reward.svg" />{" "}
                          <span>Performance Reward</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${currentPath === "/vip-reward" ||
                      currentPath === "/vip-reward"
                      ? "d-block active"
                      : "d-block"
                      }`}
                    onClick={() => setSubopen7p(!subopne7p)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a className="d-flex">
                        <img src="/assets/img/new-img/new-icon/infinity_reward.svg" />{" "}
                        <span>Infinity Reward </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopne7p ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        className={`${currentPath === "/vip-reward " ? "active" : " "
                          }`}
                      >
                        <Link to="/vip-reward">
                          <img src="/assets/img/new-img/new-icon/vip_reward.svg" />{" "}
                          <span> VIP Reward </span>
                        </Link>
                      </li>

                      <li>
                        <Link to="/salary-reward">
                          <img src="/assets/img/new-img/new-icon/salary_reward.svg" />{" "}
                          <span> Salary Reward</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/global-vip-reward">
                          <img src="/assets/img/new-img/new-icon/global_vip_reward.svg" />{" "}
                          <span> Global VIP Reward</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${currentPath === "/withdrawal" ||
                      currentPath === "/withdrawal-history"
                      ? " active"
                      : " "
                      }`}
                    onClick={() => setSubopen3p(!subopne3p)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <a>
                        <img src="/assets/img/new-img/new-icon/withdraw.svg" />{" "}
                        <span>Withdrawal </span>
                      </a>
                      <i className="fa fa-plus-circle" />
                    </div>
                    <ul className={subopne3p ? "d-none mt-3" : "d-block mt-3"}>
                      <li
                        style={{ border: "3px solid white" }}
                        className={`${currentPath === "/withdrawal" ? " active" : " "
                          }`}
                      >
                        <Link to="/withdrawal">
                          <img src="/assets/img/new-img/new-icon/withdraw.svg" />{" "}
                          <span> Withdraw</span>
                        </Link>
                      </li>
                      <li
                        style={{ border: "3px solid white" }}
                        className={`${currentPath === "/withdrawal-history"
                          ? " active"
                          : " "
                          }`}
                      >
                        <Link to="/withdrawal-history">
                          <img src="/assets/img/new-img/new-icon/withdraw_history.svg" />{" "}
                          <span>Withdraw History</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/academy">
                      <img src="/assets/img/new-img/new-icon/education.svg" />{" "}
                      <span>Academy</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/donation-fund">
                      <img src="/assets/img/new-img/new-icon/donation_funds.svg" />{" "}
                      <span>Donation Fund</span>
                    </Link>
                  </li> */}
                </ul>
                <ul className="bottom-item">
                  <li>
                    <Link to="/account">
                      <img src="/assets/img/new-img/new-icon/account.svg" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="assets/presentation.pdf" target="_blank">
                      <img src="/assets/img/new-img/new-icon/presentation.svg" />{" "}
                      <span>Presentation</span>
                    </a>
                  </li>
                  <li>
                    <Link to="#">
                      <img src="/assets/img/new-img/new-icon/quit.svg" />{" "}
                      <span>Quit</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}